/*
  This example requires some changes to your config:
  
  ```
  // tailwind.config.js
  module.exports = {
    // ...
    plugins: [
      // ...
      require('@tailwindcss/forms'),
    ],
  }
  ```
*/
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { DefaultCron } from "./components/cron-scheduler/cron-scheduler";
import { HEADER_VALUES, HeaderValType } from "./components/cron-scheduler/meta";

import React from "react";
import { getGlobalClient } from "./api-client-config";
import CreateEditRunnerForm from "./create-edit-runner-form";
import { getSecretFetcher, saveAndRunClick } from "./create-edit-runner-helper";
import useSWR from "swr";
import { ListSecretsResponse } from "./api-client/types.gen";
import { Loader } from "./components/loader";
import { postRunnerByRunnerIdExec } from "./api-client/services.gen";
import LogModal from "./components/log-modal";
import { set } from "lodash";

interface Props {
  isEdit?: boolean;
}

export default function CreateRunner(props: Props) {
  const client = getGlobalClient();
  const [logModalOpen, setLogModalOpen] = useState(false);
  const [secretStates, setSecretStates] = useState<
    { secretId: string; secretName: string; isSelected: boolean }[]
  >([]);
  const [cron, setCron] = useState<{
    scheduleMode: HeaderValType;
    cron: string[];
    humanReadableCron: string;
  }>({
    scheduleMode: HEADER_VALUES.MINUTES,
    cron: DefaultCron.split(" "),
    humanReadableCron: "",
  });
  const {
    data: secrets = [],
    error: secretError,
    isLoading: secretLoading,
    mutate,
  } = useSWR("secrets", () => getSecretFetcher(client), {
    onSuccess: handleSecretSuccess,
  });

  const selectedPreselected = secrets.map((s) => ({ ...s, isSelected: true }));
  console.log("selectedPreselected", selectedPreselected);
  const [runnerName, setRunnerName] = useState("");
  const [description, setDescription] = useState("");
  const [collectionFile, setCollectionFile] = useState<File | null>(null);
  const [environmentFile, setEnvironmentFile] = useState<File | null>(null);
  // Preferences should be stored in localstorage
  const [scheduleEnabled, setScheduleEnabled] = useState(false);
  const [runnerId, setRunnerId] = useState("");
  const [activeRunId, setActiveRunId] = useState("");
  const navigate = useNavigate();

  function handleSecretSuccess(secrets: ListSecretsResponse["secrets"]) {
    const secretsWithSelected = (secrets || []).map((secret) => {
      return {
        ...secret,
        isSelected: true,
      };
    });
    setSecretStates(secretsWithSelected);
  }

  async function handleSaveAndRunClick(runNow: boolean) {
    const runnerId = await saveAndRunClick({
      client,
      collectionFile: collectionFile!,
      collectionName: collectionFile!.name,
      environmentFile: environmentFile!,
      runnerName,
      description,
      cron,
      scheduleEnabled,
      secretIds: secretStates
        .filter((s) => s.isSelected)
        .map((s) => s.secretId),
    });

    if (runNow && runnerId) {
      setRunnerId(runnerId);
      const response = await postRunnerByRunnerIdExec({
        client,
        path: { runnerId },
      });
      if (response.error) {
        console.error(response.error);
      } else {
        if (response.data.runId) {
          setActiveRunId(response.data.runId);
        }
        setLogModalOpen(true);
      }
    }
  }

  function handleSchedulerChange(
    scheduleMode: HeaderValType,
    newCron: string[],
    humanReadableCron: string,
  ) {
    console.log("scheduleMode", scheduleMode);
    console.log("newCron", newCron);
    console.log("humanReadableCron", humanReadableCron);
    setCron({ scheduleMode, cron: newCron, humanReadableCron });
  }

  function handleScheduleToggle(enabled: boolean) {
    console.log("scheduleMode", cron.scheduleMode);
    console.log("humanReadableCron", cron.humanReadableCron);
    console.log("Cron", cron.cron);
    setScheduleEnabled(enabled);
  }

  // const disabledScheduleCss = !scheduleEnabled
  //   ? "absolute top-0 left-0 h-48 w-full opacity-30 bg-gray-700 z-auto pointer-events-none"
  //   : "";

  if (secretLoading) {
    return <Loader />;
  }

  return (
    <>
      {logModalOpen && (
        <LogModal
          open={logModalOpen}
          onClose={() => setLogModalOpen(false)}
          runnerId={runnerId}
          runId={activeRunId || ""}
          isLive={true}
        />
      )}
      <CreateEditRunnerForm
        name={runnerName}
        onChangeName={(event) => setRunnerName(event.target.value)}
        description={description}
        secrets={selectedPreselected}
        handleOnSecretChange={(secretId) => {
          console.log("secretId", secretId);
          const newSecrets = secretStates.map((s) => {
            if (s.secretId === secretId) {
              console.log("s", s);
              return { ...s, isSelected: !s.isSelected };
            }
            return s;
          });
          console.log("newSecrets", newSecrets);
          setSecretStates(newSecrets);
        }}
        onChangeDescription={(event) => setDescription(event.target.value)}
        onCancel={() => navigate("/runners")}
        onSaveClick={() => {
          handleSaveAndRunClick(false);
          navigate("/runners");
        }}
        onSaveAndRunClick={() => {
          handleSaveAndRunClick(true);
        }}
        onCollectionFileSelect={(file: File) => setCollectionFile(file)}
        onEnvironmentFileSelect={(file: File) => setEnvironmentFile(file)}
        handleSchedulerChange={handleSchedulerChange}
        handleScheduleToggle={handleScheduleToggle}
        scheduleEnabled={scheduleEnabled}
        cron={cron}
      />
    </>
  );
}
